@import "../../UI/DesignSystem/DesignSystem.scss";

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100px;
  background-color: $color-primary-base;

  p {
    font-size: 35px;
    font-weight: 600;
    color: white;
  }
}
