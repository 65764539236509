@import "../../UI/DesignSystem/DesignSystem.scss";

.HomeBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  flex-grow: 1;
  padding: 20px;

  .form-container {
    .FormInput,
    .Select {
      width: 200px;
    }
  }
}
