// FONTS
$primary-font: "Open Sans", sans-serif;
$secondary-font: "Rubik", sans-serif;

// COLORS
$color-primary-base: #49a4ff;
$color-primary-pressed: #4a90e2;
$color-primary-unread: #e3f2ff;
$color-primary-hover: rgba(227, 242, 255, 0.5);
$color-secondary-base: #5f7292;
$color-secondary-pressed: #384f6e;
$color-secondary-dark: #061324;
$color-overlay-background: rgba(6, 19, 36, 0.7);
$color-secondary-hover: rgba(95, 114, 146, 0.15);
$color-black: #000;
$color-text-grey: #7b7b7b;
$color-dark-grey: #9d9d9d;
$color-middle-grey: #d9d9d9;
$color-light-grey: #e9e9e9;
$color-app-background: #f5f5f5;
$color-field-grey: #fbfbfb;
$color-white: #fff;
$color-success-state: #00c654;
$color-success-background: #e6f9ee;
$color-error-base: #f85b58;
$color-error-dark: #dd3938;
$color-error-light: #ffecef;
$color-warning-state: #f09448;
$color-warning-background: #fff4e4;

/** SHADOWS **/
$shadow-1: 0 1px 0 0 rgba(0, 0, 0, 0.2);
$shadow-2: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
$shadow-3: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
$shadow-4: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
$shadow-5: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
$shadow-6: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
$shadow-7: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
$shadow-8: 0 12px 24px 0 rgba(0, 0, 0, 0.1);

/** TYPOGRAPHIES **/
$bold: 700;
$semibold: 600;
$regular: 400;
$light: 200;

body,
html {
  background-color: $color-app-background;
}

// BASE RULES
h1,
h2,
h3,
h4,
h5,
.typography-hero,
.typography-body,
span,
em,
p,
a,
button,
label,
input {
  font-family: $primary-font;
  color: $color-black;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin: 0;
}

li {
  list-style: none;
}

span {
  font-weight: $semibold;
}

label {
  font-size: 16px;
  font-weight: $semibold;
  color: $color-black;
}

::-webkit-input-placeholder {
  color: $color-middle-grey;
  font-size: 13px;
  font-weight: $light;
}

button {
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

// MIXINS
@mixin typography-hero {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.53px;
}

@mixin typography-h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.45px;
}

@mixin typography-h2 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.38px;
}

@mixin typography-h3 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
}

@mixin typography-h4 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
}

@mixin typography-h5 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
}

@mixin typography-body {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.46;
  letter-spacing: 0.14px;
}

@mixin typography-footnotes {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.13px;
}

@mixin typography-caption {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.11px;
}

h1 {
  @include typography-h1;
}
h2 {
  @include typography-h2;
}
h3 {
  @include typography-h3;
}
h4 {
  @include typography-h4;
}
h5 {
  @include typography-h5;
}
body {
  @include typography-body;
}

a {
  @include typography-h5;

  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
