@import "../DesignSystem/DesignSystem.scss";

.FormInput {
  width: 100%;

  label {
    display: block;
    margin-bottom: 7px;

    span {
      position: relative;

      @include typography-h5;

      color: $color-error-base;
      margin-left: 4px;
    }
  }

  input {
    @include typography-body;

    width: 100%;
    padding: 13px 15px;
    border-radius: 4px;
    box-shadow: unset;
    border: 1px solid $color-middle-grey;
    box-sizing: border-box;
    background: $color-field-grey;

    &.error {
      margin: 0;
      border: 1px solid $color-error-base;
      color: $color-error-base;
      background-color: $color-white;
    }

    &:disabled {
      color: $color-text-grey;
      border: solid 1px $color-middle-grey;
      background-color: $color-app-background;
      pointer-events: none;
    }

    &:focus {
      outline: unset;
      border: solid 1px $color-primary-base;
      background-color: $color-white;
      color: $color-black;
      box-shadow: none;
    }

    &::placeholder {
      @include typography-body;

      color: $color-text-grey;
    }
  }

  .error-message {
    position: relative;
    margin-top: 3px;
    height: 15px;
    color: $color-error-base;
    pointer-events: none;
    visibility: hidden;

    @include typography-footnotes;

    &.visible {
      visibility: visible;
    }
  }

  &.noError {
    height: fit-content;

    .error-message {
      display: none;
    }
  }
}
